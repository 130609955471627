import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Filter extends Model {

  @observable id
  @observable sn
  @observable name
  @observable factory_code
  @observable produced_at

  constructor(attributes={}) {
    super(Backend.filters_path, Backend.filter_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class FilterList extends Store {

  constructor() {
    super(Filter, Backend.filters_path)
  }

}