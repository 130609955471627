import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Material extends Model {

  @observable id
  @observable name
  @observable no
  @observable supplier_id
  @observable unit
  @observable description
  @observable contact
  @observable phone

  constructor(attributes={}) {
    super(Backend.materials_path, Backend.material_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class MaterialList extends Store {

  constructor() {
    super(Material, Backend.materials_path)
  }

}
