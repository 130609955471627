import { makeObservable, observable, action } from 'mobx'
import _ from 'lodash'
import { http, EventEmitter } from '../utils'
import Application from './Application'

export class Model extends Application {

  primary_key = 'id'

  constructor(public parent_url, public url) {
    super()
  }

  @action reset() {
    return this.modify(Reflect.construct(this.constructor, [this.parent_url, this.url]))
  }

  async load(params={}) {
    return await(this[this.primary_key] |> this.url |> http().get(?, { params })) |> this.modify
  }

  valid() {
    return true
  }

  toJS({ only, except } = {}) {
    const hash = super.toJS({ only, except })

    return _.omit(hash, ['primary_key', 'parent_url', 'url', 'requiredParams', 'toString'])
  }

  toFormData({ name, only, except } = {}) {
    let data = new FormData()

    const hash = this.toJS({ only, except })

    _.each(hash, (value, key) => {
      const field = name ? `${name}[${key}]` : key

      if (_.isArray(value)) {
        _.each(value, v => data.append(`${field}[]`, v))
      } else {
        data.append(field, _.isUndefined(value) || _.isNull(value) ? '' : value)
      }
    })

    return data
  }

  save() {
    return this[!!this[this.primary_key] ? 'update' : 'create']()
  }

  update(params, options) {
    return this.submit('patch', this.url(this[this.primary_key]), { params, options })
  }

  create() {
    return this.submit('post', this.parent_url())
  }

  destroy() {
    return this.submit('delete', this.url(this[this.primary_key]))
  }

  submit(verb, url, { params, options } = {}) {
    let args = [url]

    if (['post', 'put', 'patch'].includes(verb)) {
      args.push(params || this.toJS())
    }
    
    return http({ ...options })[verb](...args)
  }

}

const emitter = Symbol('EventEmitter')

export class Store extends Application {

  @observable current_page = 1
  @observable total_pages
  @observable total_count
  @observable page_size = 10
  @observable records = []
  @observable extra = {}
  sort = '-created_at'
  params = {}

  private [emitter] = new EventEmitter

  constructor(private model, public endpoint) {
    super()

    Object.defineProperty(this, emitter, { enumerable: false, writable: true });

    makeObservable(this)
  }

  @action reset() {
    const template = Reflect.construct(this.constructor, [this.model, this.endpoint])
    
    return this.modify({...template, [emitter]: this[emitter]})
  }

  rebuild() {
    this.modify({records: [...this.records]})
  }

  enqueue(item: Model) {
    this.records.pop()
    this.records.unshift(item)
  }

  async load(params) {
    Object.assign(this.params, params)
    
    const data = await http().get(this.endpoint(), {
      params: {
        page: this.current_page,
        page_size: this.page_size,
        sort: this.sort,
        ...this.params
      }
    })

    return this.modify({
      ...data,
      records: data.records.map((record: Model) => new this.model(record))
    }).emit('load', this.records)
  }

  emit(...args) {
    this[emitter].emit(...args)
    return this
  }

  on(...args) {
    this[emitter].on(...args)
    return this
  }

  un(...args) {
    this[emitter].listenerCount(...args)
    return this
  }

  removeAllListeners(...args) {
    this[emitter].removeAllListeners(...args)
    return this
  }

}