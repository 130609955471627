import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Board extends Model {

  @observable id
  @observable sn
  @observable imei
  @observable factory_code
  @observable produced_at

  constructor(attributes={}) {
    super(Backend.boards_path, Backend.board_path) |> makeObservable
    
    Object.assign(this, attributes)
  }

}

export class BoardList extends Store {

  constructor() {
    super(Board, Backend.boards_path)
  }

}