import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Firmware extends Model {

  @observable id = ''
  @observable compiled_at = new Date
  @observable description = ''
  @observable major = 0
  @observable minor = 0
  @observable published_at = new Date
  @observable category_id
  @observable type_id
  @observable version
  @observable checksum
  
  file = null

  constructor(attributes={}) {
    super(Backend.firmwares_path, Backend.firmware_path)
    
    this |> makeObservable |> Object.assign(?, attributes)
  }

  valid() {
    if (!this.id && !this.file) {
      alert('请选择需要上传的文件。')
      return false
    }

    return true
  }

  toJS(...args) {
    let hash = super.toJS(...args)

    if (!hash.file) {
      delete hash['file']
    }
  
    return hash
  }

  submit(verb, url) {
    return super.submit(verb, url, { 
        params: this.toFormData(), 
        options: { upload: true } 
      } 
    )
  }

}

export class FirmwareList extends Store {

  constructor() {
    super(Firmware, Backend.firmwares_path)
  }

}