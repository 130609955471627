import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Card extends Model {

  @observable id
  @observable number
  @observable owner
  @observable copied = false

  constructor(attributes={}) {
    super(Backend.cards_path, Backend.card_path) |> makeObservable
    
    Object.assign(this, attributes)
  }

}

export class CardList extends Store {

  constructor() {
    super(Card, Backend.cards_path)
  }

}