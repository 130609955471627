import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Dispatch extends Model {

  @observable id
  @observable name
  @observable count
  @observable region
  @observable dispatch_at
  @observable supplier_id
  @observable material_id

  constructor(attributes={}) {
    super(Backend.dispatches_path, Backend.dispatch_path) |> makeObservable
    Object.assign(this, attributes)
  }

  valid() {
    if (!this.dispatch_at) {
      alert('出库时间不能为空')
      return false
    }

    return true;
  }

}

export class DispatchList extends Store {

  constructor() {
    super(Dispatch, Backend.dispatches_path)
  }

}