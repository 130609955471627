import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Package extends Model {

  @observable id
  @observable imei
  @observable iccid
  @observable board_code
  @observable filter_code

  constructor(attributes={}) {
    super(Backend.packages_path, Backend.package_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class PackageList extends Store {

  constructor() {
    super(Package, Backend.packages_path)
  }

}