/*
 * @Author: luoda
 * @Date: 2023-05-31 10:44:07
 * @LastEditTime: 2023-08-07 16:58:23
 * @LastEditors: luoda
 * @Description:
 */
import axios from "axios";

const service = axios.create({
  baseURL: "",
  timeout: 5000,
});


// 响应拦截
service.interceptors.response.use(res => {
  return res;
});

// 封装请求api
const callapi = (method, url, data) => {
  return service({
    method,
    url,
    headers: {
      Accept: "application/json"
    },
    params: method === "GET" ? data : {},
    data: method === "POST" ? data : {},
  });
};

// 封装GET请求函数
export const getapi = (url, data = {}) => callapi("GET", url, data);
export const postapi = (url, data = {}) => callapi("POST", url, data);
