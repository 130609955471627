import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Role extends Model {

  @observable id
  @observable name
  @observable description
  @observable authority = []
  @observable creator_id
  @observable creator_name
  @observable users_count

  constructor(attributes={}) {
    makeObservable(super(Backend.roles_path, Backend.role_path))
    Object.assign(this, attributes)
  }

}

export class RoleList extends Store {

  constructor() {
    super(Role, Backend.roles_path)
  }

}