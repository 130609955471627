import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class BoardType extends Model {

  @observable id
  @observable name
  @observable type

  constructor(attributes={}) {
    super(Backend.board_types_path, Backend.board_type_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class BoardTypeList extends Store {

  constructor() {
    super(BoardType, Backend.board_types_path)
  }

}