import { makeObservable, observable } from 'mobx'
import _ from 'lodash'

import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Device extends Model {

  @observable id
  @observable name
  @observable state
  @observable stopped
  @observable imei
  @observable iccid
  @observable board_sn
  @observable filter_sn
  @observable amount
  @observable online

  constructor(attributes={}) {
    super(Backend.devices_path, Backend.device_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class DeviceList extends Store {

  constructor() {
    super(Device, Backend.devices_path)
  }

  find_by_imei(imei) {
    return _.find(this.records, ['imei', imei])
  }

}