import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Order extends Model {

  @observable id
  @observable _type
  @observable canceled_at
  @observable failed_at
  @observable number
  @observable paid_at
  @observable total
  @observable state
  @observable created_at
  @observable address_line
  @observable plan_title
  @observable device_name
  @observable customer_name
  @observable customer_phone
  @observable shipment_at

  constructor(attributes={}) {
    Object.assign(super(Backend.orders_path, Backend.order_path) |> makeObservable, attributes)
  }

}

export class OrderList extends Store {

  constructor() {
    super(Order, Backend.orders_path)
  }

}