import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Watering extends Model {

  @observable id
  @observable at
  @observable imei
  @observable original
  @observable device_id
  @observable customer_id
  @observable amount

  constructor(attributes={}) {
    Object.assign(super() |> makeObservable, attributes)
  }

}

export class WateringList extends Store {

  constructor(public endpoint) {
    super(Watering, endpoint)
  }

}