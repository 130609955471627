import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Plan extends Model {

  @observable id
  @observable amount
  @observable price
  @observable original_price
  @observable title
  @observable limit
  @observable expired_at
  @observable average
  @observable experience

  constructor(attributes={}) {
    Object.assign(super(Backend.plans_path, Backend.plan_path) |> makeObservable, attributes)
  }

}

export class PlanList extends Store {

  constructor() {
    super(Plan, Backend.plans_path)
  }

}