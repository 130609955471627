import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Sim extends Model {

  @observable id
  @observable iccid
  @observable board_id

  constructor(attributes={}) {
    super(Backend.sims_path, Backend.sim_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class SimList extends Store {

  constructor() {
    super(Sim, Backend.sims_path)
  }

}