import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Subscription extends Model {

  @observable id
  @observable unit
  @observable price
  @observable original_price
  @observable title
  @observable description
  @observable category_id

  constructor(attributes={}) {
    Object.assign(super(Backend.subscriptions_path, Backend.subscription_path) |> makeObservable, attributes)
  }

}

export class SubscriptionList extends Store {

  constructor() {
    super(Subscription, Backend.subscriptions_path)
  }

}