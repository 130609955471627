import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Operation extends Model {

  @observable id
  @observable date
  @observable customer_id
  @observable name
  @observable action
  @observable times

  constructor(attributes={}) {
    super(Backend.operations_path, Backend.operation_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class OperationList extends Store {

  sort = '-date'

  constructor(attributes={}) {
    super(Operation, Backend.operations_path)

    Object.assign(this, attributes)
  }

}