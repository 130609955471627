import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Factory extends Model {

  @observable id
  @observable name
  @observable code

  constructor(attributes={}) {
    super(Backend.factories_path, Backend.factory_path) |> makeObservable
    Object.assign(this, attributes)
  }

}

export class FactoryList extends Store {

  constructor() {
    super(Factory, Backend.factories_path)
  }

}