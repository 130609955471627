import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class Coupon extends Model {

  @observable id
  @observable code
  @observable amount
  @observable minimum = 0
  @observable expired_at
  @observable redeemed_at
  @observable used_at
  @observable customer_name
  @observable workflow_state
  @observable state
  @observable copied = false

  constructor(attributes={}) {
    super(Backend.coupons_path, Backend.coupon_path) |> makeObservable
    
    Object.assign(this, attributes)
  }

}

export class CouponList extends Store {

  constructor() {
    super(Coupon, Backend.coupons_path)
  }

}