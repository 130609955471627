import { makeObservable, observable } from 'mobx'
import _ from "lodash"

import { Model } from './Model'
import { Backend } from '../utils'

class Profile extends Model {

  @observable id
  @observable name
  @observable username
  @observable nickname
  @observable phone
  @observable email
  @observable token
  @observable menus = []
  @observable authorities = []

  constructor() {
    super(Backend.profile_path, Backend.profile_path)
    makeObservable(this)
  }

  can(features) {
    return _.intersection(this.authorities, _.castArray(features)).length > 0
  }

}

export const profile = new Profile