import { Client } from 'paho-mqtt'
import { v4 as uuidv4 } from 'uuid';
import { EventEmitter } from './'

interface Topic {
  event?: string
  topic: string
  regexp: string
  qos: number
}

const { emqx_endpoint, emqx_ssl, emqx_ws_port, emqx_wss_port } = window.gon
const ssl = emqx_ssl == 'true'

export class MQTT {

  private client: Client
  private emitter: EventEmitter = new EventEmitter
  topics: Topic[] = []

  constructor({
    host = emqx_endpoint,
    port = Number(ssl ? emqx_wss_port : emqx_ws_port),
    path = '/mqtt',
    client_id = uuidv4()
  }={}) {
    this.client = new Client(host, port, path, client_id)
    this.configure()
  }

  private configure(): void {
    Object.assign(this.client, {

      onConnected: () => {
        console.log('mqtt client connected')

        this.emit('mqtt:connected', this)
      },

      onConnectionLost: () => {
        console.log('mqtt client disconnected')

        this.emit('mqtt:disconnected', this)
      },

      onMessageArrived: ({ topic, payloadString: payload }) => {
        for (const { event, regexp } of this.topics) {
          const matched = topic.match(new RegExp(regexp))
    
          if (matched) {
            this.emit(event || regexp, { topic, matched, payload })
          }
        }
        // emit all message
        this.emit('mqtt:message', { topic, payload })
      },

      onMessageDelivered: ({ destinationName, payloadString: payload }) => {
        this.emit(`mqtt:sent:${destinationName}`, payload)
      }

    })
  }

  subscribe(topics: Topic[]) {
    this.topics = topics
    return this.connected ? this.doSubscribe() : this.on('mqtt:connected', this.doSubscribe, this)
  }

  private doSubscribe() {
    for (const { topic, qos } of this.topics) {
      this.client.subscribe(topic, { qos })
    }

    return this
  }

  connect(options={userName: 'fresh_water_web_client', password: ''}): Promise<MQTT> {
    return new Promise((resolve) => {
      this.on('mqtt:connected', resolve)

      this.client.connect({...options, reconnect: true, useSSL: ssl})
    })
  } 

  disconnect() {
    try {
      if (this.connected) this.client.disconnect()
    } catch (e) {
      console.log(e)
    } finally {
      this.removeAllListeners()
      return this
    }
  }

  get connected() {
    return this.client.isConnected()
  }

  send(...args) {
    const [topic, ...rest] = args

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => reject('等待 MQTT 消息应答超时'), 30_000)

      this.emitter.once(`mqtt:sent:${topic}`, payload => {
        clearTimeout(timeout)
        resolve(payload)
      })

      this.client.send(...args)
    })
  }

  // 需要开先启 EMQX 的 emqx_mod_delayed 模块
  // https://www.emqx.io/docs/zh/v5.0/messaging/mqtt-delayed-publish.html
  delay_send(seconds, ...args) {
    const [topic, ...rest] = args

    return this.send(`$delayed/${seconds}/${topic}`, ...rest)
  }

  emit() {
    try {
      this.emitter.emit(...arguments)
    } catch(e) {
      console.log(e)
    }
  }

  on() {
    this.emitter.addListener(...arguments)
    return this
  }

  un() {
    this.emitter.removeListener(...arguments)
    return this
  }

  removeAllListeners() {
    this.emitter.removeAllListeners()
    return this
  }

}