import { I18n } from "i18n-js";
import translations from "./locales.json";

const i18n = new I18n();

i18n.store(translations);

i18n.defaultLocale = 'zh-CN'

i18n.enableFallback = true;

export default i18n;