import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class User extends Model {

  @observable id
  @observable email = ''
  @observable username
  @observable name = ''
  @observable phone = ''

  constructor(attributes={}) {
    super(Backend.users_path, Backend.user_path)

    Object.assign(makeObservable(this), attributes)
  }

  toJS(...args) {
    let hash = super.toJS(...args)

    if (!hash.avatar) {
      delete hash['avatar']
    }
  
    return hash
  }

  submit(verb, url) {
    return super.submit(verb, url, { 
        params: this.toFormData(), 
        options: { upload: true } 
      } 
    )
  }

}

export class UserList extends Store {

  constructor() {
    super(User, Backend.users_path)
  }

}