import { makeObservable, observable } from 'mobx'
import { Model, Store } from './Model'
import { Backend } from '../utils'

export class FilterCategory extends Model {

  @observable id
  @observable name
  @observable type

  photo = null

  constructor(attributes={}) {
    super(Backend.filter_categories_path, Backend.filter_category_path)
    this |> makeObservable |> Object.assign(?, attributes)
  }

  valid() {
    if (!this.id && !this.photo) {
      alert('请选择需要上传的图片。')
      return false
    }

    return true
  }

  toJS(...args) {
    let hash = super.toJS(...args)

    if (!hash.photo) {
      delete hash['photo']
    }
  
    return hash
  }

  submit(verb, url) {
    return super.submit(verb, url, { 
        params: this.toFormData(), 
        options: { upload: true } 
      } 
    )
  }

}

export class FilterCategoryList extends Store {

  constructor() {
    super(FilterCategory, Backend.filter_categories_path)
  }

}