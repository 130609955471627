import { useState } from 'react'

export { http } from './http'
export { image_path } from './image'
export * as Backend from './services.js'
export { default as I18n } from './i18n.js'
export { EventEmitter, emitter } from './emitter'
export { MQTT } from './mqtt'
export { getapi, postapi } from './axios'


export const useApplicationState = (closure: Function) => useState(closure)[0]