import { makeObservable, observable, action, computed } from 'mobx'
import Application from './Application'

export class Loading extends Application {

  @observable state = false

  constructor(attributes={}) { 
    super()
    makeObservable(this)
    this.modify(attributes)
  }

  @action start() {
    this.state = true
  }

  @action end() {
    this.state = false
  }

  @computed get loaded() {
    return this.state == false
  }

  @action async around(closure) {
    this.start()
    
    try {
      await closure()
    } finally {
      this.end()
    }
  }

}